<template>
  <div class="app-page">
    <Navbar />
    <div class="main-wrapper">
      <LeftMenu />
      <div class="registry-wrapper p-0 bg-transparent">
        <div class="row justify-content-end m-0 pr-2" v-if="isAdmin">
          <div class="add-subscription">
            <button type="button" class="btn" >
              Add Subscription
            </button>
          </div>
        </div>


      </div>

    </div>

  </div>

</template>

<script>
import { mapGetters } from "vuex";
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import Navbar from "../components/header/Navbar.vue";

export default {
  name: "Configuration",

  components: {
    Navbar,
    LeftMenu,
  },

  data() {
      return {
       
        User: false,
        isAdmin: false,
        showLoader: 0,
      };
    },

  computed: {
    ...mapGetters("auth", ["user"]),
   
  },

  methods: {
  



  },
  watch: {},
  mounted() {

    if (localStorage.getItem("permissions") == 2) {
      this.User = true;
      
    } else if (localStorage.getItem("permissions") == 1) {
      this.isAdmin = true;
    }


  },
  created() { },
};
</script>