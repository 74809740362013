var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('button',{ref:"openEditConfigurationModal",staticClass:"d-none",attrs:{"data-toggle":"modal","data-target":"#EditConfigurationModal"}}),_c('div',{staticClass:"modal fade",attrs:{"id":"EditConfigurationModal","tabindex":"-1","aria-labelledby":"EditConfigurationLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered modal-dialog-scrollable"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header border-0"},[_c('h6',{staticClass:"modal-title",attrs:{"id":"EditConfigurationLabel"}},[_vm._v("Edit Configuration")]),_c('button',{ref:"closeModalEditConfiguration",staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body pt-0"},[_c('form',[_c('div',{staticClass:"registry-sign-up-form-content"},[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"config_type"}},[_vm._v("Type")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.details.type),expression:"details.type"}],staticClass:"form-control pl-2",attrs:{"type":"text","id":"config_type","readonly":""},domProps:{"value":(_vm.details.type)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.details, "type", $event.target.value)}}})])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.details.description),expression:"details.description",modifiers:{"trim":true}}],staticClass:"form-control pl-3",class:{
                          'is-invalid': _vm.$v.details.description.$error,
                          'is-valid': !_vm.$v.details.description.$invalid,
                        },attrs:{"type":"text","id":"description","placeholder":"Enter Description"},domProps:{"value":(_vm.details.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.details, "description", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.description.required)?_c('span',[_vm._v("Description is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.details.value),expression:"details.value",modifiers:{"trim":true}}],staticClass:"form-control pl-3",class:{
                          'is-invalid': _vm.$v.details.value.$error,
                          'is-valid': !_vm.$v.details.value.$invalid,
                        },attrs:{"type":"number","id":"value","placeholder":"Enter Value"},domProps:{"value":(_vm.details.value)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.details, "value", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.details.value.required)?_c('span',[_vm._v("Value is required")]):_vm._e(),(!_vm.$v.details.value.numeric)?_c('span',[_vm._v("Value must be a number")]):_vm._e()])])])])])]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('button',{ref:"closeModalEditConfiguration",staticClass:"btn btn-primary mr-2",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":function($event){return _vm.resetModal()}}},[_c('span',[_vm._v("Cancel")])]),_c('button',{staticClass:"btn btn-primary pl-3",attrs:{"type":"button","disabled":_vm.isLoading},on:{"click":function($event){return _vm.updateConfiguration()}}},[(_vm.isLoading)?_c('div',{staticClass:"lds-dual-ring"}):_c('span',[_vm._v("Save Changes")])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"description"}},[_vm._v("Description "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"value"}},[_vm._v("Value "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
}]

export { render, staticRenderFns }