<template>
  <div>
    <button class="d-none" data-toggle="modal" data-target="#AddMessage" ref="openModalMessage"></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="AddMessage">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title m-0">Edit Subscription</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" ref="closeModalMessage">
              <img src="../../assets/images/cross.png" alt="close" />
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="mb-3">
                <label for="editName" class="form-label">Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="editName"
                  v-model.trim="editForm.name"
                  :class="{ 'is-invalid': $v.editForm.name.$error }"
                />
                <div class="invalid-feedback" v-if="$v.editForm.name.$error">
                  <span>Name is required</span>
                </div>
              </div>
              <div class="mb-3">
                <label for="editDescription" class="form-label">Description</label>
                <input
                  type="text"
                  class="form-control"
                  id="editDescription"
                  v-model.trim="editForm.description"
                  :class="{ 'is-invalid': $v.editForm.description.$error }"
                />
                <div class="invalid-feedback" v-if="$v.editForm.description.$error">
                  <span>Description is required</span>
                </div>
              </div>
              <div class="mb-3">
                <label for="editAmount" class="form-label">Amount</label>
                <input
                  type="Number"
                  class="form-control"
                  id="editAmount"
                  v-model.trim="editForm.amount"
                  :class="{ 'is-invalid': $v.editForm.amount.$error }"
                />
                <div class="invalid-feedback" v-if="$v.editForm.amount.$error">
                  <span>Amount is required</span>
                </div>
              </div>
              <div class="mb-3">
                <label for="editTransactions" class="form-label">Transactions</label>
                <input
                  type="Number"
                  class="form-control"
                  id="editTransactions"
                  v-model.trim="editForm.transactions"
                  :class="{ 'is-invalid': $v.editForm.transactions.$error }"
                />
                <div class="invalid-feedback" v-if="$v.editForm.transactions.$error">
                  <span>Transactions are required</span>
                </div>
              </div>
              <!-- Support Type Dropdown -->
              <div class="mb-3">
                <label for="editSupportType" class="form-label">Support Type</label>
                <select
                  id="editSupportType"
                  class="form-control"
                  v-model="editForm.support_type"
                  :class="{ 'is-invalid': $v.editForm.support_type.$error }"
                >
                  <option value="0">Dedicated Support</option>
                  <option value="1">Premium Support</option>
                </select>
                <div class="invalid-feedback" v-if="$v.editForm.support_type.$error">
                  <span>Support type is required</span>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Close
            </button>
            <button type="button" class="btn btn-primary" @click="updateSubscription()">
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "EditSubscriptionModal",
  data() {
    return {
      editForm: {
        name: "",
        description: "",
        amount: "",
        transactions: "",
        support_type: "0", // Default to "Dedicated Support"
      },
    };
  },
  validations: {
    editForm: {
        name: { required },
        description: { required },
        amount: { required },
        transactions: { required },
        support_type: { required },
      },
  },
  
  methods: {
    updateSubscription() {
      this.$v.$touch(); // Trigger validation checks
      if (this.$v.$invalid) {
        console.error("Form validation failed!");
        return; // Stop submission if invalid
      }

      const requestData = {
        name: this.editForm.name,
        description: this.editForm.description,
        amount: this.editForm.amount,
        transactions: this.editForm.transactions,
        support_type: this.editForm.support_type, // Add support_type to the request
      };

      axios.post(`${process.env.VUE_APP_API_URL}updateSubscriptionPlan/${this.editForm.id}`, requestData)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.$root.$emit("updateSubscription", response);
            console.log("Subscription updated successfully:", response.data);
            this.dataReset();
            this.$refs.closeModalMessage.click();
            this.$swal({
              title: "Subscription Update!",
              text: response.data.message,
              icon: "success",
              buttons: true, // Enable buttons (OK)
            })
          } else {
            console.error("Failed to update subscription:", response.data.message);
          }
        })
        .catch((error) => {
          console.error("Error updating subscription:", error);
          alert("An error occurred while updating the subscription plan.");
        });
    },
    dataReset() {
      this.editForm = {
        name: "",
        description: "",
        amount: "",
        transactions: "",
        support_type: "0", // Reset support type
      };
      this.$v.$reset(); // Reset validation state
    },
  },
  mounted() {
    this.$root.$on("editSubscription", (plan) => {
      this.editForm = { ...plan };
      this.$refs.openModalMessage.click();
    });
  },
};
</script>

<style scoped>
.custom-select,
.form-control,
input.form-control {
  padding-left: 10px;
}
</style>
