<template>
    <div class="app-page">
      <Navbar />
      <div class="main-wrapper">
        <LeftMenu />
        <div class="registry-wrapper p-0 bg-transparent overflow-hidden">
          <div class="">
            <!-- <StoresFilter  @clearFilter="handleClearFilter" @showChange="gettingValueChanged" /> -->
            <StoresCard  @clearFilter="handleClearFilter" @showChange="gettingValueChanged" :showLoader="showLoader" :count="count"  />
          </div>
          <div class="home-registry-wrapper">
            <StoresTop :count="count" :searched="filter.search" @clearFilter="handleClearFilter" />
            <div
              v-if="showLoader == 1"
              class="lds-dual-ring-registry"
              id="loading"
            ></div>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade"
                id="registry-table"
                v-bind:class="{ active: tabindex == 0, show: tabindex == 0 }"
                role="tabpanel"
                aria-labelledby="registry-table-tab"
              >
                
                <StoresTable :message="parentMessage" :customerList="customerList" :showLoader="showLoader" :isFilterApplied="isFilterApplied"/>
              </div>
            </div>
            <StoresBottom :page="pager" :count="count" />
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import axios from "axios";
  import { mapGetters, mapActions } from "vuex";
  import LeftMenu from "../components/leftmenu/LeftMenu.vue";
  import StoresTop from "../components/stores/StoresTop.vue";
  import StoresTable from "../components/stores/StoresTable.vue";
  import StoresBottom from "../components/stores/StoresBottom.vue";
  import Navbar from "../components/header/Navbar.vue";
//   import StoresFilter from "../components/stores/StoresFilter.vue";
  import StoresCard from "../components/stores/StoresCards.vue";
  
  export default {
    name: "Stores",
  
    components: {
      Navbar,
      LeftMenu,
      StoresTop,
      StoresTable,
      StoresBottom,
    //   StoresFilter,
      StoresCard,
    },
  
    data: function() {
      return {
        parentMessage: false,
        errorMessage: null,
        customerList: [],
        showLoader: 0,
        page: 1,
        pager: {},
        perPage: 3,
        pages: [],
        success: null,
        error: null,
        count: 0,
        tabindex: 0,
        filter: {},
        pageSize: "15",
        totalStatus: {},
        isFilterApplied: false, // New state to check if filters are applied
  
      };
    },
  
    computed: {
      ...mapGetters("auth", ["user"]),
    },
  
    methods: {
      ...mapActions("auth"),
      gettingValueChanged(value) {
        this.parentMessage = value;
      },
      handleClearFilter(clearedFilter) {
        console.log(clearedFilter,"adddd")
        this.filter = clearedFilter;
  
        // Check if any filter is applied (non-empty values in the filter object)
        const isFilterApplied = Object.keys(this.filter).some(key => {
          const value = this.filter[key];
          // Check if value is a string and non-empty after trimming, or if value is not empty
          return (typeof value === 'string' && value.trim() !== "") || (typeof value === 'number' && value !== null && value !== "");
        });
  
        if (isFilterApplied) {
          // If any filter is applied, reset to page 1
          this.page = 1;
          this.isFilterApplied = true;
  
        } else {
          // If no filter is applied (filters are cleared), fetch page from the URL
          const urlParams = new URLSearchParams(window.location.search);
          const page = urlParams.get('page') || 1; // Default to page 1 if no page parameter
          this.page = parseInt(page, 10);
          this.isFilterApplied = false;
  
          // Log the page number to the console
          console.log("Page from URL:", this.page);
        }
  
        this.getStoresData(); // Fetch data based on cleared filters or URL page
      },
  
      getStoresData() {
        this.showLoader = 1;
        // const user_id = this.user && this.user.id ? this.user.id : localStorage.getItem('loginUserId');
  
        axios
          .get(
            `${process.env.VUE_APP_API_URL}fetchStores?page=${this.page}&size=${
              this.pageSize ? this.pageSize : ""}&first_name=${
              this.filter.first_name ? this.filter.first_name : ""
            }&store_name=${
              this.filter.store_name ? this.filter.store_name : ""
            }&search=${
              this.filter.search ? this.filter.search : ""
            }&email=${
              this.filter.email ? this.filter.email : ""
            }&last_name=${
              this.filter.last_name ? this.filter.last_name : ""
            }&archived=${
              this.filter.archived ? this.filter.archived : ""
            }`,
            {}
          )
          .then((response) => {
            if (response.data.statusCode == 200) {
                console.log(response,"hwhee")
              this.customerList = response.data.data.stores;
              this.pager = response.data.data;
              this.count = response.data.data.total;
              this.totalStatus = response.data.data.stores;
              this.showLoader = 0;
            } else {
              this.errorMessage = response.data.message;
              this.showLoader = 0;
            }
          });
      },
    },
    watch: {
      "$route.query.page": {
        immediate: true,
        handler(newPage) {
          newPage = parseInt(newPage) || 1;
          if (
            newPage !== this.page
          ) {
            this.page = newPage;
            this.showLoader = 1;
            // const user_id = this.user && this.user.id ? this.user.id : localStorage.getItem('loginUserId');
            axios
            .get(
            `${process.env.VUE_APP_API_URL}fetchStores?page=${this.page}&size=${
              this.pageSize ? this.pageSize : ""}&first_name=${
              this.filter.first_name ? this.filter.first_name : ""
            }&store_name=${
              this.filter.store_name ? this.filter.store_name : ""
            }&search=${
              this.filter.search ? this.filter.search : ""
            }&email=${
              this.filter.email ? this.filter.email : ""
            }&last_name=${
              this.filter.last_name ? this.filter.last_name : ""
            }&archived=${
              this.filter.archived ? this.filter.archived : ""
            }`,
            {}
          )
              .then((response) => {
                if (response.data.statusCode == 200) {
                    console.log(response,"allaq")
                  this.customerList = response.data.data.stores;
                  this.pager = response.data.data;
                  this.count = response.data.data.total;
                  this.totalStatus = response.data.data;
                  this.showLoader = 0;
                } else {
                  this.errorMessage = response.data.message;
                  this.showLoader = 0;
                }
              });
          }
        },
      },
    },
    mounted() {
        console.log("hellow")
      this.getStoresData();
      this.$root.$on("update-data",()=>{
        this.getStoresData();
      });
      this.$root.$on("filterOrders", (data) => {
        console.log(data,"adl")
        this.filter = data;
        console.log(this.filter);
      
  
        if (data || Object.keys(data).length > 0) {
          const urlParams = new URLSearchParams(window.location.search);
          const page = urlParams.get('page') || 1;  
          this.page = parseInt(page, 10);
  
          console.log("Page from URL:", this.page);
        } else {
          this.page = 1;
        }
  
        this.getStoresData();
      });
  
  
      this.$root.$on("searchForCustomer", (data) => {
        this.filter = data;
        console.log(this.filter);
  
        // Check if search is empty or contains only whitespace
        if (this.filter && this.filter.search && this.filter.search.trim() !== "") {
          this.page = 1;
          this.isFilterApplied = true;
  
        } else {
          const urlParams = new URLSearchParams(window.location.search);
          const page = urlParams.get('page') || 1;  // Default to page 1 if no page parameter
          this.page = parseInt(page, 10);
          this.isFilterApplied = false;
  
          console.log("Page from URL:", this.page);
        }
  
        this.getStoresData();
      });
      this.$root.$on("viewHandle", (data) => {
        this.tabindex = data;
      });
    },
    created() {},
  };
  </script>
  