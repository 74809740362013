<template>
    <div class="app-page">
      <Navbar />
      <div class="main-wrapper">
        <LeftMenu />
        <div class="registry-wrapper p-0 bg-transparent">
          <!-- Admin Add Configuration Button -->
          <div class="row justify-content-end m-0 pr-2" v-if="isAdmin">
            <div class="add-subscription">
              <button type="button" class="btn btn-primary" @click="createConfigurationPage">
                Add Configuration
              </button>
            </div>
          </div>
  
          <!-- Modal for Adding Configurations -->
          <ConfigurationModal  />
          <UpdateConfigurationModal  />
  
          <!-- Loader -->
          <div v-if="showLoader" class="lds-dual-ring-registry" id="loading"></div>
  
          <!-- No Configurations Message -->
          <div
            v-if="!showLoader && adminConfigurations.length === 0"
            style="height: 80%;"
            class="w-100 d-flex justify-content-center align-items-center"
          >
          <div class="empty-list col-md-4">
        <div class="empty-icon">
          <img src="../assets/images/cofig1.png" width="23">
        </div>
        <p class="mt-2 mb-0">No configuration found</p>
        <span class="text-center">Try Adding one to see the configuration.</span>
      </div>
          </div>
  
          <!-- Display Admin Configurations -->
          <div v-if="!showLoader && adminConfigurations.length > 0" class="configurations-container">
  <div
    v-for="(config) in adminConfigurations"
    :key="config.id"
    class="configuration-card"
  >
    <!-- Display Configuration Type -->
    <p>
      <strong>Configuration Type:</strong> {{ config.typeRelation.name }}
    </p>

    <!-- Display Value -->
    <p>
      <strong>Value:</strong> {{ config.value }}
    </p>

    <!-- Display Truncated Description -->
    <p>
      <strong>Description:</strong> {{ truncateDescription(config.description) }}
    </p>

    <!-- Delete Button -->
    <button class="btn btn-danger btn-sm" @click="deleteConfiguration(config.id)">
      Delete
    </button>
    <button
      class="btn btn-warning btn-sm ml-2"
      @click="updateConfiguration(config)"
    >
      Update
    </button>
  </div>
</div>


        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import { mapGetters } from "vuex";
  import LeftMenu from "../components/leftmenu/LeftMenu.vue";
  import Navbar from "../components/header/Navbar.vue";
  import ConfigurationModal from "../components/configurations/ConfigurationModal.vue";
  import UpdateConfigurationModal from "../components/configurations/UpdateConfigurationModal.vue";
  
  export default {
    name: "AdminConfiguration",
    components: {
      Navbar,
      LeftMenu,
      ConfigurationModal,
      UpdateConfigurationModal
    },
    data() {
      return {
        showAddPaymentModal: false,
        adminConfigurations: [], // Store fetched configurations here
        isAdmin: false,
        showLoader: false,
      };
    },
    computed: {
      ...mapGetters("auth", ["user"]),
    },
    methods: {
        createConfigurationPage() {
         this.$root.$emit("openConfigurationModal")
         this.showAddPaymentModal = true
      },
      truncateDescription(description) {
      if (description.length > 100) {
        return description.slice(0, 100) + '...';
      }
      return description;
    },
      fetchAdminConfigurations() {
        this.showLoader = true;
        axios
          .get(`${process.env.VUE_APP_API_URL}admin/getAllAdminConfigurations`)
          .then((response) => {
            if (response.data.statusCode === 200) {
              this.adminConfigurations = response.data.data;
            } else {
              this.adminConfigurations = [];
            }
          })
          .catch(() => {
            this.$swal("Error", "Failed to fetch configurations.", "error");
          })
          .finally(() => {
            this.showLoader = false;
          });
      },
      updateConfiguration(config) {
    this.$root.$emit("editConfiguration", config);
  },
      deleteConfiguration(id) {
        this.showLoader = true;
        axios
          .delete(`${process.env.VUE_APP_API_URL}admin/deleteAdminConfiguration/${id}`)
          .then((response) => {
            if (response.data.statusCode === 200) {
              this.adminConfigurations = this.adminConfigurations.filter(
                (config) => config.id !== id
              );
              this.$swal("Success", "Configuration deleted successfully.", "success");
              this.fetchAdminConfigurations();
              this.$root.$emit("fetchTpes")
            }
          })
          .catch(() => {
            this.$swal("Error", "Failed to delete configuration.", "error");
          })
          .finally(() => {
            this.showLoader = false;
          });
      },
    },
    mounted() {
        this.$root.$on("refreshConfigurations", ()=>{
            this.fetchAdminConfigurations();
        });
      const permissions = localStorage.getItem("permissions");
      if (permissions == 1) {
        this.isAdmin = true;
        this.fetchAdminConfigurations(); // Fetch configurations when the component is mounted
      }
    },
  };
  </script>
  
  <style scoped>
  .configurations-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 16px;
  }
  
  .configuration-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 16px;
    width: calc(33.333% - 16px);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .configuration-card h4 {
    margin-bottom: 8px;
  }
  .configurations-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.configuration-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  width: 300px;
  word-wrap: break-word; /* Ensures long text wraps */
  overflow: hidden; /* Hides overflowing content */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.configuration-card p {
  margin: 8px 0;
}

.btn {
  margin-top: 10px;
}

  
  .lds-dual-ring-registry {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  </style>
  