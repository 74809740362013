<template>
    <div>
      <button class="d-none" data-toggle="modal" data-target="#EditConfigurationModal" ref="openEditConfigurationModal"></button>
      <div class="modal fade" id="EditConfigurationModal" tabindex="-1" aria-labelledby="EditConfigurationLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header border-0">
              <h6 class="modal-title" id="EditConfigurationLabel">Edit Configuration</h6>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" ref="closeModalEditConfiguration">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body pt-0">
              <form>
                <div class="registry-sign-up-form-content">
                  <div class="container-fluid p-0">
                    <div class="row">
                      <!-- Type Field -->
                      <div class="col-12">
                        <div class="form-group">
                            <label for="config_type">Type</label>
                            <input
                            type="text"
                            class="form-control pl-2"
                            id="config_type"
                            v-model="details.type"
                            readonly
                            />
                        </div>
                        </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label for="description">Description <span class="text-danger">*</span></label>
                          <input
                            type="text"
                            class="form-control pl-3"
                            id="description"
                            placeholder="Enter Description"
                            v-model.trim="details.description"
                            :class="{
                              'is-invalid': $v.details.description.$error,
                              'is-valid': !$v.details.description.$invalid,
                            }"
                          />
                          <div class="invalid-feedback">
                            <span v-if="!$v.details.description.required">Description is required</span>
                          </div>
                        </div>
                      </div>
  
                      <!-- Value Field -->
                      <div class="col-12">
                        <div class="form-group">
                          <label for="value">Value <span class="text-danger">*</span></label>
                          <input
                            type="number"
                            class="form-control pl-3"
                            id="value"
                            placeholder="Enter Value"
                            v-model.trim="details.value"
                            :class="{
                              'is-invalid': $v.details.value.$error,
                              'is-valid': !$v.details.value.$invalid,
                            }"
                          />
                          <div class="invalid-feedback">
                            <span v-if="!$v.details.value.required">Value is required</span>
                            <span v-if="!$v.details.value.numeric">Value must be a number</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Buttons -->
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn btn-primary mr-2" @click="resetModal()" data-dismiss="modal" aria-label="Close" ref="closeModalEditConfiguration">
                    <span>Cancel</span>
                  </button>
                  <button type="button" class="btn btn-primary pl-3" @click="updateConfiguration()" :disabled="isLoading">
                    <div v-if="isLoading" class="lds-dual-ring"></div>
                    <span v-else>Save Changes</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { required, numeric } from "vuelidate/lib/validators";
  import axios from "axios";
  
  export default {
    name: "UpdateConfigurationModal",
    data() {
      return {
        types: [],
        details: {
          id: null, // Add ID to identify configuration
          type: "",
          description: "",
          value: "",
        },
        isLoading: false,
      };
    },
    validations: {
      details: {
        type: { required },
        description: { required },
        value: { required, numeric },
      },
    },
    methods: {
      fetchTypes() {
        axios
          .get(`${process.env.VUE_APP_API_URL}admin/getAllTypes`)
          .then((response) => {
            this.types = response.data.data || [];
          })
          .catch((error) => {
            this.$swal("Error!", error.response?.data?.message || "Unable to fetch types", "error");
          });
      },
      resetModal() {
        this.details = { id: null, type: "", description: "", value: "" };
        this.$v.$reset();
      },
      updateConfiguration() {
        this.$v.$touch();
        if (this.$v.$pending || this.$v.$error) return;

        this.isLoading = true;

        // Prepare data to send in the API request
        const configData = {
            type: this.details.type, // Ensure type matches the API's expected key
            description: this.details.description,
            value: this.details.value,
        };

        axios
            .put(`${process.env.VUE_APP_API_URL}admin/updateAdminConfiguration/${this.details.id}`, configData)
            .then((response) => {
            // Show success alert
            this.$swal("Success!", response.data.message, "success");

            // Reset modal data and emit event for refreshing configurations
            this.resetModal();
            this.$root.$emit("refreshConfigurations");
            })
            .catch((error) => {
            // Show error alert
            this.$swal("Error!", error.response?.data?.message || "Something went wrong", "error");
            })
            .finally(() => {
            this.isLoading = false;
            const elem = this.$refs.closeModalEditConfiguration;
            if (elem) elem.click(); // Close the modal programmatically
            });
        }

    },
    mounted() {
      this.fetchTypes();
      this.$root.$on("editConfiguration", (config) => {
        console.log(config,"adss")
        this.details.id = config.id;
        this.details.type = config.typeRelation.name || "";
        this.details.description = config.description || "";
        this.details.value = config.value || "";
        const elem = this.$refs.openEditConfigurationModal;
        if (elem) elem.click();
      });
    },
  };
  </script>
  