<template>
    <div>
      <button class="d-none" data-toggle="modal" data-target="#AddConfigurationModal" ref="openAddConfigurationModal"></button>
      <div class="modal fade" id="AddConfigurationModal" tabindex="-1" aria-labelledby="AddConfigurationLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header border-0">
              <h6 class="modal-title" id="AddConfigurationLabel">Add Configuration</h6>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" ref="closeModalAddConfiguration">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body pt-0">
              <form>
                <div class="registry-sign-up-form-content">
                  <div class="container-fluid p-0">
                    <div class="row">
                      <!-- Type Field -->
                      <div class="col-12">
                        <div class="form-group">
                          <label for="config_type">Type <span class="text-danger">*</span></label>
                          <select
                            class="form-control pl-2"
                            id="config_type"
                            v-model.trim="details.type"
                            :class="{
                              'is-invalid': $v.details.type.$error,
                              'is-valid': !$v.details.type.$invalid,
                            }"
                          > 
                          <option value="">Select Type</option>
                          <option v-for="type in types" :key="type.id" :value="type.id">
                            {{ type.name }}
                          </option>
                          </select>
                          <div class="invalid-feedback">
                            <span v-if="!$v.details.type.required">Type is required</span>
                          </div>
                        </div>
                      </div>
  
                      <!-- Description Field -->
                      <div class="col-12">
                        <div class="form-group">
                          <label for="description">Description <span class="text-danger">*</span></label>
                          <input
                            type="text"
                            class="form-control pl-3"
                            id="description"
                            placeholder="Enter Description"
                            v-model.trim="$v.details.description.$model"
                            :class="{
                              'is-invalid': $v.details.description.$error,
                              'is-valid': !$v.details.description.$invalid,
                            }"
                          />
                          <div class="invalid-feedback">
                            <span v-if="!$v.details.description.required">Description is required</span>
                          </div>
                        </div>
                      </div>
  
                      <!-- Value Field -->
                      <div class="col-12">
                        <div class="form-group">
                          <label for="value">Value <span class="text-danger">*</span></label>
                          <input
                            type="number"
                            class="form-control pl-3"
                            id="value"
                            placeholder="Enter Value"
                            v-model.trim="$v.details.value.$model"
                            :class="{
                              'is-invalid': $v.details.value.$error,
                              'is-valid': !$v.details.value.$invalid,
                            }"
                          />
                          <div class="invalid-feedback">
                            <span v-if="!$v.details.value.required">Value is required</span>
                            <span v-if="!$v.details.value.numeric">Value must be a number</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Buttons -->
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn btn-primary mr-2" @click="resetModal()" data-dismiss="modal" aria-label="Close" ref="closeModalAddConfiguration">
                    <span>Cancel</span>
                  </button>
                  <button type="button" class="btn btn-primary pl-3" @click="createConfiguration()" :disabled="isLoading">
                    <div v-if="isLoading" class="lds-dual-ring"></div>
                    <span v-else>Add Configuration</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { required, numeric } from "vuelidate/lib/validators";
  import axios from "axios";
  
  export default {
    name: "ConfigurationModal",
    data() {
      return {
        types: [],
        details: {
          type: "",
          description: "",
          value: "",
        },
        isLoading: false,
      };
    },
    validations: {
      details: {
        type: { required },
        description: { required },
        value: { required, numeric },
      },
    },
    methods: {
      createConfiguration() {
        this.$v.$touch();
        if (this.$v.$pending || this.$v.$error) return;
  
        this.isLoading = true;
        const configData = {
         configType: this.details.type,
          description: this.details.description,
          value: this.details.value,
        };
  
        axios
          .post(`${process.env.VUE_APP_API_URL}admin/createAdminConfiguration`, configData)
          .then((response) => {
            this.$swal("Success!", response.data.message, "success");
            this.resetModal();
            this.$root.$emit("refreshConfigurations");
            this.fetchTypes();
          })
          .catch((error) => {
            this.$swal("Error!", error.response?.data?.message || "Something went wrong", "error");
          })
          .finally(() => {
            this.isLoading = false;
            const elem = this.$refs.closeModalAddConfiguration;
            if (elem) elem.click();
          });
      },
      fetchTypes() {
      // Fetch available types from API
      axios
        .get(`${process.env.VUE_APP_API_URL}admin/getAllTypes`)
        .then((response) => {
          this.types = response.data.data || []; // Update types data
        })
        .catch((error) => {
          this.$swal("Error!", error.response?.data?.message || "Unable to fetch types", "error");
        });
    },
      resetModal() {
        this.details.type = "";
        this.details.description = "";
        this.details.value = "";
        this.$v.$reset();
      },
    },
    mounted() {
        this.$root.$on("fetchTpes",()=>{
this.fetchTypes();
        });
        this.fetchTypes();
      this.$root.$on("openConfigurationModal", () => {
        const elem = this.$refs.openAddConfigurationModal;
        if (elem) elem.click();
      });
    },
  };
  </script>
  
  <style scoped>
  /* Add relevant styles here */
  </style>
  