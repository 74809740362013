<template>
  <div class="table-container" v-bind:class="classObject">
    <!-- Render table only if customerList is not empty -->
    <b-table
      v-if="customerList.length > 0"
      :items="processedCustomerList"
      :fields="fields"
      hover
      responsive
      class="mt-3 b-datatable cursor-pointer"
      :sort-compare="customSort"
    >
      <!-- Store Name -->
      <template v-slot:cell(store_name)="data">
        {{ data.item.store_name }}
      </template>

      <!-- User Name -->
      <template v-slot:cell(user_name)="data">
        {{ data.item.user_name }}
      </template>

      <!-- User Email -->
      <template v-slot:cell(user_email)="data">
        {{ data.item.user_email }}
      </template>

      <!-- Registered Date -->
      <template v-slot:cell(createdAt)="data">
        {{ formatDate(data.item.createdAt) }}
      </template>

      <!-- Actions -->
      <template v-slot:cell(actions)="data">
        <div 
          @click="data.item.user.status === 0 ? restoreStore(data.item) : deleteStore(data.item)"
          class="cursor-pointer"
        >
          <img
            v-if="data.item.user.status === 0"
            src="../../assets/images/restore.png"
            alt="Restore"
            title="Restore"
            width="25px"
          />
          <img
            v-else
            src="../../assets/images/delete-red.png"
            alt="Delete"
            title="Delete"
          />
        </div>
      </template>
    </b-table>

    <!-- Empty State Messages -->
    <div
      v-if="showLoader === 0 && customerList.length === 0 && isFilterApplied"
      class="row m-0 h-100 d-flex flex-column justify-content-center align-items-center"
    >
      <div class="empty-list col-md-4">
        <div class="empty-icon">
          <img src="../../assets/images/store.png" width="23">
        </div>
        <p class="mt-2 mb-0">No Results Found</p>
        <span class="text-center">Try adjusting your filters or clearing them.</span>
      </div>
    </div>

    <div
      v-if="showLoader === 0 && customerList.length === 0 && !isFilterApplied"
      class="row m-0 h-100 d-flex flex-column justify-content-center align-items-center"
    >
      <div class="empty-list col-md-4">
        <div class="empty-icon">
          <img src="../../assets/images/store.png" width="23">
        </div>
        <p class="mt-2 mb-0">No stores found</p>
        <span class="text-center">Try adjusting your filters or clearing them.</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "StoresTable",
  data() {
    return {
      fields: [
        { key: "store_name", label: "Store Name", sortable: true },
        { key: "user_name", label: "User Name", sortable: true },
        { key: "user_email", label: "Email", sortable: true },
        { key: "createdAt", label: "Registered Date", sortable: true },
        { key: "actions", label: "Actions", class: "text-center" },
      ],
      processedCustomerList: [], // Flattened data for the table
    };
  },
  props: {
    customerList: {
      type: Array,
      required: true,
    },
    message: Boolean,
    showLoader: Number,
    isFilterApplied: Boolean, // Passed from parent component
  },
  computed: {
    classObject() {
      return {
        "reg-table": this.message,
        "reg2-table": !this.message,
      };
    },
  },
  watch: {
  customerList: {
    immediate: true,
    handler(newList) {
      this.processedCustomerList = newList.map((customer) => ({
        ...customer,
        store_name:`${customer.StoreName || ""}`,
        user_name: `${customer.user?.first_name || ""} ${customer.user?.last_name || ""}`,
        user_email: customer.user?.email || "No Email",
        createdAt: customer.user?.createdAt || null,
      }));
      console.log("Processed List:", this.processedCustomerList);
    },
  },
},

  methods: {
    formatDate(date) {
      const parsedDate = new Date(date);
      if (isNaN(parsedDate)) {
        return "Invalid Date"; // Handle invalid dates
      }
      const options = { year: "numeric", month: "long", day: "numeric" };
      return parsedDate.toLocaleDateString("en-US", options);
    },
    customSort(a, b, key) {
      if (key === "user_name") {
        return a.user_name.localeCompare(b.user_name);
      } else if (key === "user_email") {
        return a.user_email.localeCompare(b.user_email);
      } else if (key === "createdAt") {
        return new Date(a.createdAt) - new Date(b.createdAt);
      }
      return a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0;
    },
    deleteStore(item) {
      this.$swal({
        title: "This action is reversible",
        text: "Are you sure you want to delete this store?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`${process.env.VUE_APP_API_URL}archiveStore`, {
              store_id: item.StoreID,
            })
            .then(() => {
              this.$swal("Deleted!", "The store has been deleted.", "success");
              this.$root.$emit("update-data");
            })
            .catch(() => {
              this.$swal("Error!", "Unable to delete the store.", "error");
            });
        }
      });
    },
    restoreStore(item) {
      this.$swal({
        title: "Restore this store?",
        text: "Are you sure you want to restore this store?",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, restore it!",
        cancelButtonText: "No, cancel!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`${process.env.VUE_APP_API_URL}restoreStore`, {
              store_id: item.StoreID,
            })
            .then(() => {
              this.$swal("Restored!", "The store has been restored.", "success");
              this.$root.$emit("update-data");
            })
            .catch(() => {
              this.$swal("Error!", "Unable to restore the store.", "error");
            });
        }
      });
    },
  },
};
</script>

