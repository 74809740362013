<template>
  <div class="table-container" v-bind:class="classObject">
    <!-- Render refund table only if refundOrder is not empty -->
    <b-table
      v-if="refundOrder.length > 0"
      :items="refundOrder"
      :fields="fields"
      hover
      responsive
      class="mt-3 b-datatable cursor-pointer"
    >
      <template v-slot:cell(order_number)="data">
        {{ data.item?.order?.order_number || 'N/A' }}
      </template>

      <template v-slot:cell(customer_id)="data">
        <div class="">{{ data.item.CustomerID || 'N/A' }}</div>
      </template>

      <template v-slot:cell(phone)="data">
        <div class="">{{ data.item.phone || 'N/A' }}</div>
      </template>

      <template v-slot:cell(email)="data">
        <div class="">{{ data.item.email || 'N/A' }}</div>
      </template>

      <template v-slot:cell(refund_id)="data">
        <div class="">{{ data.item.RefundID || 'N/A' }}</div>
      </template>

      <template v-slot:cell(risk_level)="data">
        <div class=" text-capitalize">
          {{ capitalizeRiskLevel(data.item.Risk_level) || '-' }}
        </div>
      </template>
    </b-table>

    <!-- Show message when refundOrder is empty -->
    <div v-if="showLoader === 0 && refundOrder.length === 0 && isFilterApplied" class="row m-0 h-100 d-flex flex-column justify-content-center align-items-center">
      <div class="empty-list col-md-4">
        <div class="empty-icon">
          <img src="../../assets/images/icon/refund.png">
        </div>
        <p class="mt-2 mb-0">No Results Found</p>
        <span class="text-center">Try adjusting your filters or clearing them.</span>
      </div>
    </div>

    <div v-if="showLoader === 0 && refundOrder.length === 0 && !isFilterApplied" class="row m-0 h-100 d-flex flex-column justify-content-center align-items-center">
      <div class="empty-list col-md-4">
        <div class="empty-icon">
          <img src="../../assets/images/icon/refund.png">
        </div>
        <p class="mt-2 mb-0">No Refund Yet?</p>
        <span class="text-center">Add products to your store and start selling to see refunds here.</span>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "RefundTable",

  data() {
    return {
      fields: [
        { key: 'order_number', label: 'Order Number', sortable: true },
        { key: 'customer_id', label: 'Customer ID', sortable: true },
        { key: 'phone', label: 'Phone', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'refund_id', label: 'Refund ID', sortable: true },
        { key: 'risk_level', label: 'Refund Risk Level', sortable: true }
      ]
    };
  },
  props: {
    refundOrder: {
      type: Array,
    },
    message: Boolean,
    showLoader: Number,
    isFilterApplied: Boolean, // Pass this from the parent component

  },
  methods: {
    capitalizeRiskLevel(riskLevel) {
      if (!riskLevel) return '-';
      return riskLevel
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
  },
  computed: {
    classObject: function () {
      return {
        'reg-table': this.message,
        'reg2-table': !this.message,
      };
    },
  },
};
</script>
